<template>
  <div id="home">
    <div class="container">
      <div class="body">
        <div class="image">
          <img
            src="../assets/header.jpg"
            alt="Portal de Recursos de Turismo Ind&iacute;gena"
            uk-img
          />
        </div>
        <div class="intro">
          <div class="uk-section-small uk-text-center">
            <p class="uk-text-center">
                <a href="https://indigenoustourism.net/" class="uk-button uk-button-primary" target="_blank" rel="noopener">
                  View English Version
                </a>
              </p>
            <h1 class="uk-margin-remove">
              Portal de Recursos de Turismo Ind&iacute;gena
            </h1>
            <h2 class="uk-margin-remove uk-text-bold">
              Convocatoria para Recursos Informativos
            </h2>
            <p class="subtitle">
              Por favor ay&uacute;denos a construir la mayor base de datos
              <br />
              de recursos informativos de turismo indígena que se haya creado
            </p>
          </div>
        </div>
        <div id="description" class="uk-background-muted">
          <div class="uk-section-small">
            <div class="uk-container">
              <p class="uk-text-bold">
                ¿QUÉ ES EL PORTAL DE RECURSOS DE TURISMO IND&Iacute;GENA?
              </p>
              <p>
                <span class="uk-text-bold"
                  >El Diálogo sobre Turismo Indígena en las Américas (ITCA)
                </span>
                reúne un Portal de Recursos Informativos de Turismo Indígena, un
                inventario de herramientas y recursos informativos fácilmente
                accesible y completo para apoyar a los pueblos indígenas, a sus
                partes interesadas y socios para ayudar a desarrollar y
                administrar mejor el turismo. El Portal se aprovechará para
                lograr un turismo indígena sostenible en las Américas - Estados
                Unidos, Canadá, México, América Central, Caribe y América del
                Sur - para desarrollar y reconstruir el sector tras la pandemia
                COVID-19 2020-2022.
              </p>
            </div>
          </div>
        </div>
        <div id="cta">
          <div class="uk-section-small">
            <div class="uk-container">
              <p>
                <span class="uk-text-bold">ITCA</span> es una red consultiva de
                líderes indígenas y líderes no indígenas de turismo que trabajan
                juntos para recuperarse de los impactos del COVID-19,
                desarrollar capacidad y resiliencia en el turismo y ganar
                respeto e inclusión para el liderazgo indígena turístico en los
                gobiernos e industria turística.
              </p>
            </div>
          </div>
        </div>
        <div id="large">
          <div class="uk-section-small">
            <div class="uk-container">
              <h3 class="uk-text-center">
                ¿Interesado en ayudar a las comunidades indígenas compartiendo
                un recurso informativo?
              </h3>
              <h4 class="uk-text-center uk-text-bold txt-blue">
                CÓMO PUEDE AYUDAR
              </h4>
              <p>
                El <span class="uk-text-bold">ITCA</span> está recopilando
                actualmente las herramientas y recursos informativos aplicables
                existentes para incluir en el Portal de Recursos Informativos de
                turismo indígena. Los tipos de herramientas y áreas temáticas
                que estamos recopilando se enumeran a continuación para su
                revisión.
              </p>
              <p>
                Revise los temas de envío y las solicitudes de herramientas a
                continuación antes de enviarlos. Las presentaciones serán
                analizadas por un equipo para su inclusión en el Índice. Sus
                recursos informativos se colocarán en una base de datos y se
                considerarán para su inclusión en el sitio web.
              </p>
              <p>
                Tenga en cuenta que, en caso de que tenga un recurso
                informativos planificado o en desarrollo pero no está disponible
                actualmente, después de la convocatoria inicial, el Índice ITCA
                revisará y aceptará envíos de forma continua. Este es un proceso
                continuo que evolucionará y crecerá con el tiempo.
              </p>
              <p class="uk-text-center">
                Para vincular sus recursos al índice, utilice el Formulario de
                Envío de Recursos Informativos .
              </p>
              <p class="uk-text-center">
                <a
                  href="https://form.jotform.com/222204194840146"
                  class="uk-button uk-button-primary"
                  target="_blank"
                  >ENV&Iacute;O DE RECURSOS
                </a>
              </p>
            </div>
          </div>
        </div>
        <div id="blue-block">
          <div class="uk-section-small">
            <div class="uk-container">
              <h4 class="uk-text-center uk-text-bold">
                TEMAS TURÍSTICOS SOLICITADOS
              </h4>
              <div class="uk-child-width-1-3@s uk-grid" uk-grid>
                <div>
                  <ul class="uk-list">
                    <li>Acuerdos intergubernamentales y del sector privado</li>
                    <li>Áreas protegidas y gestionadas</li>
                    <li>Asociaciones con organizaciones no indígenas</li>
                    <li>
                      Códigos de conducta para visitantes y operadores
                      turísticos
                    </li>
                    <li>Comercialización y promoción de destinos</li>
                    <li>Conservación del medio ambiente</li>
                    <li>Creación de capacidades</li>
                    <li>Derechos de propiedad y de los usuarios</li>
                    <li>Derechos de los pueblos indígenas</li>
                    <li>Desarrollo de destinos indígenas</li>
                    <li>Desarrollo de la fuerza laboral</li>
                    <li>Desarrollo de productos</li>
                    <li>Desarrollo del liderazgo indígena</li>
                    <li>Desarrollo empresarial</li>
                    <li>Educación financiera</li>
                    <li>Emprendimiento</li>
                  </ul>
                </div>
                <div>
                  <ul class="uk-list">
                    <li>Gastronomía y agroturismo</li>
                    <li>Género</li>
                    <li>Gestión de compras y cadena de suministro</li>
                    <li>Gestión de crisis y riesgos</li>
                    <li>Gestión de recursos</li>
                    <li>Gestión de visitantes</li>
                    <li>Gobernanza y gestión de destinos</li>
                    <li>Gobierno, representación e inclusión</li>
                    <li>Hospitalidad</li>
                    <li>Impuestos e incentivos</li>
                    <li>Industrias culturales y creativas</li>
                    <li>Infraestructura e instalaciones</li>
                    <li>Interpretación</li>
                    <li>Inversión y finanzas</li>
                    <li>Marketing digital y redes sociales</li>
                    <li>Normas y certificaciones</li>
                    <li>Participación de las partes interesadas</li>
                  </ul>
                </div>
                <div>
                  <ul class="uk-list">
                    <li>Patrimonio cultural y conocimientos tradicionales</li>
                    <li>Planificación del turismo indígena</li>
                    <li>Planificación estratégica y planes de acción</li>
                    <li>Propiedad intelectual y cultural</li>
                    <li>Protección e interacción con la vida silvestre</li>
                    <li>
                      Residuos sólidos, aguas residuales y sustancias nocivas
                    </li>
                    <li>Resiliencia climática</li>
                    <li>Resiliencia pandémica (COVID-19)</li>
                    <li>Salud y bienestar</li>
                    <li>Satisfacción de visitantes y clientes</li>
                    <li>Seguridad y protección</li>
                    <li>Sistemas de gestión sostenible</li>
                    <li>Supervisión y evaluación</li>
                    <li>Tecnología y datos</li>
                    <li>Transporte y movilidad</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="tools">
          <div class="uk-section-small">
            <div class="uk-container">
              <h4 class="uk-text-center uk-text-bold">
                HERRAMIENTAS Y TIPOS DE RECURSOS SOLICITADOS
              </h4>
              <div class="uk-grid uk-child-width-1-3@s">
                <div>
                  <div>
                    <ul class="uk-list">
                      <li>Artículos revisados por pares</li>
                      <li>Buenas prácticas</li>
                      <li>Directrices y herramientas</li>
                      <li>Estándares y certificaciones</li>
                      <li>Estrategias y planes</li>
                      <li>Estudios de casos</li>
                    </ul>
                  </div>
                </div>
                <div>
                  <ul class="uk-list">
                    <li>Herramientas de monitoreo y evaluación</li>
                    <li>Historias y entrevistas</li>
                    <li>Informes y libros blancos</li>
                    <li>Legislación y regulación</li>
                    <li>Libros</li>
                    <li>Medios de comunicación</li>
                  </ul>
                </div>
                <div>
                  <div>
                    <ul class="uk-list">
                      <li>Otras redes y grupos de turismo comunitario</li>
                      <li>Políticas</li>
                      <li>Principios</li>
                      <li>Cursos de capacitación y otros recursos</li>
                      <li>Redes y grupos de turismo indígena</li>
                      <li>Seminarios web, presentaciones y videos</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="dealine">
          <div class="uk-section-small">
            <div class="uk-container">
              <div uk-grid class="uk-grid uk-grid-divider uk-child-width-1-3@s">
                <div>
                  <div>
                    <h4>FECHA LÍMITE</h4>
                    <p class="uk-margin-remove">
                      Tan pronto como sea posible.<br />
                      La primera convocatoria de recursos informativos se
                      cerrará el
                    </p>
                    <h3 class="uk-margin-remove uk-text-bold txt-red">
                      16 de diciembre de 2022
                    </h3>
                  </div>
                </div>
                <div>
                  <div>
                    <h4>Envío</h4>
                    <p>
                      Para enviar herramientas y recursos informativos, vaya al
                      <a
                        href="https://form.jotform.com/222204194840146"
                        target="_blank"
                        >Formulario de Envío de Recursos</a
                      >
                      Informativos para compartir un enlace. Toda la información
                      recopilada está destinada a apoyar a las comunidades
                      turísticas indígenas y se compartirá a nivel mundial y
                      público. Todos los datos y documentos incluirán la fuente
                      de la información y la fecha de creación del material.
                    </p>
                  </div>
                </div>
                <div>
                  <div>
                    <h4>Contacto</h4>
                    <p>
                      Para cualquier pregunta, por favor contáctenos en
                      <a
                        class="email"
                        href="mailto:itca@gwu.edu"
                        target="_blank"
                      >
                        itca@gwu.edu
                      </a>
                      y cc: Danielle Lewis Jones en
                      <a
                        class="email"
                        href="mailto:ddlj0608@gwmail.gwu.edu"
                        target="_blank"
                        >ddlj0608@gwmail.gwu.edu</a
                      >
                    </p>
                  </div>
                </div>
                <div class="uk-width-1-1">
                  <div>
                    <p class="uk-text-center">
                      <a
                        href="https://form.jotform.com/222204194840146"
                        target="_blank"
                        class="uk-button uk-button-primary"
                        >ENV&Iacute;O DE RECURSOS</a
                      >
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="logos">
          <div class="uk-section-small">
            <div class="uk-container">
              <h4 class="uk-text-center uk-text-bold">MIEMBROS</h4>
              <div>
                <div uk-slider="autoplay: true; autoplay-interval: 4000">
                  <div
                    class="uk-position-relative uk-visible-toggle"
                    tabindex="-1"
                  >
                  <div class="uk-slider-container uk-light">
                    <ul class="uk-slider-items uk-child-width-1-1">
                      <li>
                        <img
                          src="../assets/logos-bar1.jpg"
                          width=""
                          height=""
                          alt=""
                        />
                      </li>
                      <li>
                        <img
                          src="../assets/logos-bar2.jpg"
                          width=""
                          height=""
                          alt=""
                        />
                      </li>
                      <li>
                        <img
                          src="../assets/logos-bar3.jpg"
                          width=""
                          height=""
                          alt=""
                        />
                      </li>
                      <li>
                        <img
                          src="../assets/logos-bar4.jpg"
                          width=""
                          height=""
                          alt=""
                        />
                      </li>
                    </ul>
                    </div>
                    <div class="uk-visible@m">
                        <a class="uk-position-center-left-out uk-position-small uk-button-primary" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
                        <a class="uk-position-center-right-out uk-position-small uk-button-primary" href="#" uk-slidenav-next uk-slider-item="next"></a>
                    </div>
                  </div>
                  <ul
                    class="uk-slider-nav uk-dotnav uk-flex-center uk-margin"
                  ></ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="footer">
          <div class="uk-section-small">
            <div class="uk-container">
              <h4 class="uk-text-center">COMIT&Eacute; DE DIRECCI&Oacute;N</h4>
              <div class="uk-flex uk-flex-center">
                <ul class="uk-grid uk-text-center uk-flex uk-flex-middle">
                  <li>
                    <img src="../assets/OAS.png" alt="OAS" uk-img />
                  </li>
                  <li>
                    <img
                      src="../assets/US-BUREAU-INDIAN-AFFAIRS.png"
                      alt="US Bureau Indian Affairs"
                      uk-img
                    />
                  </li>
                  <li>
                    <img src="../assets/iits.jpg" alt="IITS" uk-img />
                  </li>
                </ul>
              </div>
              <p class="uk-text-center color-white">
                <a href="https://indigenoustourism.net/" target="_blank" rel="noopener">
                  View English Version
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  title: "Homepage Title",
  components: {},
};
</script>
<style scoped>
html,
body {
  font-size: 18px;
}

#home {
  background-color: #000;
}
.color-white a{
  color:#fff;
}
.container {
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
}

.body {
  background-color: #fff;
  font-size: 18px;
}

h1,
h2,
h3,
h4 {
  font-family: "33535gillsansmt" !important;
}

h1 {
  font-size: 35px;
  color: #d60012;
}

h2 {
  font-size: 57px;
  color: #00798f;
}

.subtitle {
  font-size: 36px;
  line-height: 42px;
}

.txt-blue {
  color: #00798f;
}

.txt-red {
  color: #d60012;
}

.uk-list > :nth-child(n + 2) {
  margin-top: 0;
}

#description {
  background-color: #f3ebdf;
  color: #000;
}

#cta {
  background-color: #d9b57b;
  color: #000;
}

#blue-block {
  background-color: #00788e;
  color: #fff;
}

#blue-block h4 {
  color: #fff;
}

#tools {
  background-color: #d9b57b;
  color: #000;
}

#dealine a {
  color: #00788e;
}

#dealine h4 {
  font-weight: bold;
  color: #000;
  text-transform: uppercase;
}

#logos {
  background-color: #f3ebdf;
}

#footer {
  background-color: #00788e;
  color: #fff;
}

#footer h4 {
  color: #fff;
  font-weight: bold;
}

.email {
  color: #00788e;
  text-decoration: underline;
}

.uk-button-primary {
  background-color: #d60011;
  color: #fff !important;
  border-radius: 8px;
}

.uk-button-primary:hover {
  background-color: #af0110f8;
  color: #fff !important;
  border-radius: 8px;
}

.uk-dotnav > .uk-active > a {
  background-color: #d60011;
}
</style>
