<template>
  <router-view/>
</template>

<style>
@font-face {
	font-family: '33535gillsansmt';
	src: url('../src/assets/fonts/33535gillsansmt.eot');
	src: local('33535gillsansmt'), url('../src/assets/fonts/33535gillsansmt.woff') format('woff'), url('../src/assets/fonts/33535gillsansmt.ttf') format('truetype');
}
#app {
  font-family: '33535gillsansmt' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  font-size: 18px;
}
</style>
